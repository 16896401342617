export const MESSAGES = {
  LOGIN: {
    Registered: "Thank you for registering, you may now log in"
  },
  BASKET: {
    Added: "Added to basket",
    NoQuantity: "Not enough tickets left. Please reduce your quantity."
  },
  CHECKOUT: {
    Added: "Added to basket",
    NoQuantity: "Not enough tickets left. Please reduce your quantity."
  },
  ALERTS: {
    MULTIPLE_VENUES: 'You cannot have a ticket for more than one venue in your basket. Please purchase your existing tickets or remove them to purchase from another venue.'
  },
  VENUE: {
    SoldOut: "There are no tickets availble for this venue on this date. The venue could be closed or tickets have sold out."
  },
  TICKETS: {
    None: "You have no tickets."
  },
  ERRORS: {
    BasketError: 'Unable to proceed to checkout. Please check your network connection and try again.',
    VenueManaged: 'This venue is already managed.'
  },
};