var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-8"},[_c('h3',{staticClass:"my-8 text-xl font-semibold text-red-200"},[_vm._v("Details")]),_vm._v(" "),_c('div',{staticClass:"md:grid md:grid-cols-2 md:gap-6"},[_c('div',{staticClass:"mb-8 md:mb-0"},[_c('label',{staticClass:"block mb-2 font-semibold text-grey-300"},[_vm._v("Name")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.venue.name),expression:"venue.name"}],class:("w-full p-2 mb-5 text-sm border rounded-md b-grey-200 disabled:background-grey-200" + (_vm.errorStyle(
          'name'
        ))),attrs:{"type":"text","disabled":_vm.loading},domProps:{"value":(_vm.venue.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.venue, "name", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"mb-8 md:mb-0"},[_c('label',{staticClass:"block mb-2 font-semibold text-grey-300"},[_vm._v("Image")]),_vm._v(" "),_c('input',{class:("w-full p-2 mb-5 text-sm border rounded-md b-grey-200 disabled:background-grey-200" + (_vm.errorStyle(
          'image'
        ))),attrs:{"type":"file","accept":"image/*","disabled":_vm.loading},on:{"change":function($event){return _vm.$emit('uploadImage', $event)}}}),_vm._v(" "),(_vm.venue.image)?_c('img',{staticClass:"w-full",attrs:{"src":_vm.venue.image,"alt":((_vm.venue.name) + " image")}}):_vm._e(),_vm._v(" "),(_vm.venue.image)?_c('button',{staticClass:"\n          flex\n          items-center\n          justify-center\n          px-3\n          py-2\n          mt-4\n          ml-2\n          text-sm text-white\n          rounded-lg\n          bg-orange-200\n          disabled:opacity-50\n        ",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('deleteImage')}}},[_c('icon',{staticClass:"w-4 h-4 mr-2",attrs:{"name":"trash"}}),_vm._v("\n        Delete image\n      ")],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"mb-8 md:mb-0"},[_c('label',{staticClass:"block mb-2 font-semibold text-grey-300"},[_vm._v("Description")]),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.venue.description),expression:"venue.description"}],class:("w-full h-56 p-4 text-sm border rounded-md b-grey-200 disabled:background-grey-200" + (_vm.errorStyle(
          'description'
        ))),attrs:{"disabled":_vm.loading},domProps:{"value":(_vm.venue.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.venue, "description", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"mb-8 md:mb-0"},[_c('label',{staticClass:"block mb-2 font-semibold text-grey-300"},[_vm._v("Rules")]),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.venue.rules),expression:"venue.rules"}],class:("w-full h-56 p-4 text-sm border rounded-md b-grey-200 disabled:background-grey-200" + (_vm.errorStyle(
          'rules'
        ))),attrs:{"disabled":_vm.loading},domProps:{"value":(_vm.venue.rules)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.venue, "rules", $event.target.value)}}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }