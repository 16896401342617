var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-8"},[_c('h3',{staticClass:"my-8 text-xl font-semibold text-red-200"},[_vm._v("\n        Tickets ("+_vm._s(_vm.venue.tickets.length)+")\n    ")]),_vm._v(" "),(!_vm.editMode)?_c('div',{staticClass:"my-4"},[_vm._v("\n        You need to add venue details above and click create venue before\n        adding any tickets.\n    ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.venue.tickets),function(ticket,index){return _c('div',{key:("ticket-" + (ticket.id)),class:("p-2" + (index % 2 ? ' bg-white' : ' bg-grey-100'))},[_c('div',{staticClass:"mt-10 md:grid md:grid-cols-2 md:gap-6"},[_c('div',{staticClass:"mb-8 md:mb-0"},[_c('label',{staticClass:"block mb-2 font-semibold text-grey-300"},[_vm._v("Ticket Name")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(ticket.name),expression:"ticket.name"}],class:("w-full p-2 mb-5 text-sm border rounded-md b-grey-200 disabled:background-grey-200" + (ticket.id === 0 &&
                            _vm.manageVenueErrorFields.indexOf('name') > -1
                                ? ' border-red-200'
                                : '')),attrs:{"type":"text","disabled":_vm.loading},domProps:{"value":(ticket.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(ticket, "name", $event.target.value)}}}),_vm._v(" "),_c('label',{staticClass:"block mb-2 font-semibold text-grey-300"},[_vm._v("Ticket Cost")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(ticket.cost),expression:"ticket.cost"}],class:("w-full p-2 mb-5 text-sm border rounded-md b-grey-200 disabled:background-grey-200" + (ticket.id === 0 &&
                            _vm.manageVenueErrorFields.indexOf('cost') > -1
                                ? ' border-red-200'
                                : '')),attrs:{"type":"text","disabled":_vm.loading},domProps:{"value":(ticket.cost)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(ticket, "cost", $event.target.value)}}}),_vm._v(" "),_c('label',{staticClass:"block mb-2 font-semibold text-grey-300"},[_vm._v("Ticket Daily Limit")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(ticket.daily_limit),expression:"ticket.daily_limit"}],class:("w-full p-2 mb-5 text-sm border rounded-md b-grey-200 disabled:background-grey-200" + (ticket.id === 0 &&
                            _vm.manageVenueErrorFields.indexOf('daily_limit') >
                                -1
                                ? ' border-red-200'
                                : '')),attrs:{"type":"number","disabled":_vm.loading},domProps:{"value":(ticket.daily_limit)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(ticket, "daily_limit", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"mb-8 md:mb-0"},[_c('label',{staticClass:"block mb-2 font-semibold text-grey-300"},[_vm._v("Ticket Description")]),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(ticket.description),expression:"ticket.description"}],class:("w-full h-56 p-4 text-sm border rounded-md b-grey-200 disabled:background-grey-200" + (ticket.id === 0 &&
                            _vm.manageVenueErrorFields.indexOf('description') >
                                -1
                                ? ' border-red-200'
                                : '')),attrs:{"disabled":_vm.loading},domProps:{"value":(ticket.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(ticket, "description", $event.target.value)}}})])]),_vm._v(" "),_c('div',{staticClass:"flex items-center justify-end"},[(ticket.id > 0)?_c('button',{staticClass:"flex items-center justify-center px-3 py-2 ml-2 text-sm text-white bg-orange-200 rounded-lg disabled:opacity-50",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('deleteTicket', ticket.id)}}},[_c('icon',{staticClass:"w-4 h-4 mr-2",attrs:{"name":"trash"}}),_vm._v("\n                Delete ticket\n            ")],1):_vm._e(),_vm._v(" "),_c('button',{staticClass:"flex items-center justify-center px-3 py-2 ml-2 text-sm text-white bg-orange-200 rounded-lg disabled:opacity-50",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('saveTicket', ticket)}}},[_c('icon',{staticClass:"w-4 h-4 mr-2",attrs:{"name":"save"}}),_vm._v("\n                Save ticket\n            ")],1)])])}),_vm._v(" "),_c('div',{staticClass:"flex items-center justify-end py-8"},[_c('button',{staticClass:"flex items-center justify-center px-3 py-2 text-sm text-white bg-red-200 rounded-lg disabled:opacity-50",attrs:{"disabled":_vm.manageVenueNewTicket || _vm.loading || !_vm.editMode},on:{"click":function($event){return _vm.$emit('addNewTicket')}}},[_c('icon',{staticClass:"w-4 h-4 mr-2",attrs:{"name":"plus"}}),_vm._v("\n            Add new ticket\n        ")],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }