<template>
    <div class="p-4 mb-5 text-sm font-bold bg-grey-100 rounded-xl">
        {{ text }}
    </div>
</template>

<script>
export default {
    props: ["text"]
};
</script>
