var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col w-full"},[(_vm.live)?_c('div',{staticClass:"flex items-center w-full h-12 px-2 border justify-items bg-grey-300 border-grey-300"},[(_vm.live)?_c('icon',{staticClass:"mr-2 text-lg text-white w-7 h-7",attrs:{"name":"ticket"}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"flex-grow font-semibold text-white"},[_vm._v("\n            "+_vm._s(_vm.live ? _vm.ticket.name : " ")+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"px-3 py-1 text-lg font-semibold text-white bg-orange-200 rounded-full"},[_vm._v("\n            "+_vm._s(_vm.formatCurrency(_vm.ticket.cost))+"\n        ")])],1):_vm._e(),_vm._v(" "),(_vm.live && _vm.auth && !_vm.loggedIn)?_c('div',{staticClass:"p-4 text-sm text-white border-b border-l border-r border-grey-200 text-grey-300"},[_vm._m(0)]):_vm._e(),_vm._v(" "),(_vm.live && (!_vm.auth || _vm.loggedIn))?_c('div',{staticClass:"p-4 text-sm text-white border-b border-l border-r border-grey-300 text-grey-300"},[_c('p',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.ticket.description))]),_vm._v(" "),_c('div',{staticClass:"flex flex-col flex-wrap items-center w-full md:flex-row"},[(_vm.ticket.availability)?_c('quantity-selector-component',{staticClass:"md:mr-2",attrs:{"qty":_vm.qty},on:{"up":_vm.up,"down":_vm.down}}):_vm._e(),_vm._v(" "),(_vm.ticket.availability)?_c('button',{staticClass:"flex items-center w-full px-4 py-2 mb-4 font-semibold text-center text-white bg-white bg-red-200 rounded-md cursor-pointer md:mb-0 justify-content md:w-auto",on:{"click":function($event){return _vm.$emit('book', {
                        ticket_id: _vm.ticket.id,
                        venue_id: _vm.ticket.venue_id,
                        qty: _vm.qty
                    })}}},[_c('span',{staticClass:"flex-1"},[_vm._v(_vm._s(_vm.ticket.user === 0 ? "Add to cart" : "Add to cart again"))]),_vm._v(" "),_c('icon',{staticClass:"w-4 h-4 ml-2 text-lg text-white",attrs:{"name":"chevron-right"}})],1):_vm._e(),_vm._v(" "),(!_vm.ticket.availability)?_c('span',{staticClass:"flex items-center w-full px-4 py-2 font-semibold text-center text-white bg-white rounded-md bg-grey-300 justify-content md:w-auto"},[_c('span',{staticClass:"flex-1"},[_vm._v("Sold out")]),_vm._v(" "),_c('icon',{staticClass:"w-4 h-4 ml-2 text-lg text-white",attrs:{"name":"x-circle"}})],1):_vm._e(),_vm._v(" "),(
                    _vm.ticket.status !== '' ||
                        _vm.ticket.error !== '' ||
                        _vm.ticket.success !== ''
                )?_c('span',{staticClass:"flex-1"}):_vm._e(),_vm._v(" "),(_vm.ticket.error !== '')?_c('status-component',{staticClass:"w-full md:w-auto",attrs:{"status":_vm.ticket.error,"type":"error"}}):_vm._e(),_vm._v(" "),(_vm.ticket.success !== '')?_c('status-component',{staticClass:"w-full md:w-auto",attrs:{"status":_vm.ticket.success,"type":"success"}}):_vm._e(),_vm._v(" "),(
                    _vm.ticket.error === '' &&
                        _vm.ticket.status !== '' &&
                        _vm.ticket.success === ''
                )?_c('status-component',{staticClass:"w-full md:w-auto",attrs:{"status":_vm.ticket.status}}):_vm._e()],1)]):_vm._e(),_vm._v(" "),(!_vm.live)?_c('div',{staticClass:"border h-44 bg-grey-200 border-grey-200 bg-opacity-10"},[_vm._v("\n         \n    ")]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v("\n            You must "),_c('a',{staticClass:"text-red-200",attrs:{"href":"/login"}},[_vm._v("log in")]),_vm._v(" in\n            order to book.\n        ")])}]

export { render, staticRenderFns }