<template>
  <div class="w-full max-w-5xl px-4 mx-auto">
    <home-banner-component
      imageURL="/images/bg2.jpg"
      message="Find your next fishing venue to GoFish!"
    ></home-banner-component>
  </div>
</template>

<script>
export default {};
</script>
